import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: calc(100vh - 40px);
  display: grid;
  align-items: center;
  background-color: #dfeff9;
  padding: 20px;
`;

const WrapperInner = styled.div`
  width: 100%;
  height: 100%;
  max-height: 800px;
  display: grid;
  align-items: normal;
`;

const Brand = styled.h1`
  color: #4a4a4a;
  font-family: 'Roboto Slab', sans-serif;
  font-size: 1.7em;
  font-weight: 400;
  margin: 0;
`;

const Description = styled.p`
  align-self: center;
  max-width: 737px;
  color: #4a4a4a;
  font-family: 'Roboto Slab', sans-serif;
  font-size: 2.25em;
  font-weight: 400;
  margin: 0;
`;

const SmallDescription = styled.p`
  font-family: 'Roboto Slab', sans-serif;
  font-size: 1em;
  align-self: center;
  color: #4a4a4a;
  margin: 0;
`;

const News = styled.p`
  font-family: 'Roboto Slab', sans-serif;
  font-size: 1em;
  align-self: center;
  color: #4a4a4a;
  margin: 0;
  span {
    font-weight: 800;
    font-size: 0.75em;
  }
  a {
    font-weight: 800;
    color: #4a4a4a;
    text-decoration: none;
    &:hover {
      border-bottom: 1px solid #4a4a4a;
    }
  }
`;

const SayHiButton = styled.a`
  align-self: end;
  min-width: 200px;
  display: grid;
  background-color: none;
  border: 2px solid #4a4a4a;
  color: #4a4a4a;
  font-family: 'Roboto Slab', sans-serif;
  font-size: 1em;
  font-weight: 800;
  padding: 15px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  &:hover {
    background-color: #4a4a4a;
    color: white;
  }
  &:active,
  &:focus {
    outline: none;
  }
  a:hover {
    background-color: #4a4a4a;
    color: white;
  }
  @media (min-width: 737px) {
    max-width: 15%;
  }
`;

const App = () => (
  <Wrapper>
    <WrapperInner>
      <Brand>The Platypus Company</Brand>
      <Description>
        <strong>Digital development studio.</strong>
        <br />
        We build uniquely functional tools that empower your business.
      </Description>
      <SmallDescription>
        Design {'\u2192'} Develop {'\u2192'} Deliver
      </SmallDescription>
      <News>
        Check out our{' '}
        <a href="https://medium.com/@_platypus_" target="_blank">
          Platyposts
        </a>{' '}
        where we dive into web/mobile development, design, and more!
      </News>
      <SayHiButton href="mailto: hi@platypus.dev">
        Say Hi!
      </SayHiButton>
    </WrapperInner>
  </Wrapper>
);

export default App;
